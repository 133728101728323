import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const userAPI = {
  loginRequest: async (loginData) => axios.post(`${baseURL}/login`, loginData).then((res) => res.data),
  getCpUser: async () => axios.get(`${process.env.REACT_APP_BASE_URL}/getuser`).then((res) => res.data),
  logoutCall: async () => axios.get(`${baseURL}/logout`).then((res) => res.data),
  changePassword: async (dt) => axios.put(`${baseURL}/change-password`, dt).then((res) => res.data),
  totalPortfolio: async () => axios.get(`${baseURL}/total-portfolio`).then((res) => res.data),
  depositHistory: async () => axios.get(`${baseURL}/get-deposit-history`).then((res) => res.data),
  withdrawHistory: async () => axios.get(`${baseURL}/get-withdraw-history`).then((res) => res.data),
  myTransactions: async () => axios.get(`${baseURL}/my-transactions`).then((res) => res.data),
  getMyBalance: async () => axios.get(`${baseURL}/get-account-balance`).then((res) => res.data),
  myClients: async () => axios.get(`${baseURL}/my-clients`).then((res) => res.data),
  sendWithdrwalRequest: async (profit) => axios.post(`${baseURL}/withdraw-profit`, profit).then((res) => res.data)
};

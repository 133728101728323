import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
// material
import { styled, useTheme } from '@material-ui/core/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { setAuthorizationToken } from '../../utils/setAuthorizationToken';
import { setIsAuthenticated } from '../../features/appSlice';
import { getUserRequest } from '../../features/userSlice';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem('cpToken');
  useEffect(() => {
    if (!token) {
      setAuthorizationToken(false);
      dispatch(setIsAuthenticated(false));

      navigate('/');
      return;
    }
    if (token) {
      const { exp } = decode(JSON.parse(token));
      if (exp * 1000 > Date.now()) {
        dispatch(setIsAuthenticated(true));
        setAuthorizationToken(JSON.parse(token));
        dispatch(getUserRequest());
      } else {
        localStorage.removeItem('cpToken');
        dispatch(setIsAuthenticated(false));
        setAuthorizationToken(false);
      }
    }
    // eslint-disable-next-line
  }, [token, dispatch]);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

import { PropTypes } from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const location = useLocation();

  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={{ from: location.state?.from }} state={{ from: location.state?.from }} replace />
  );
};
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};
export default PrivateRoute;

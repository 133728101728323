import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAuthorizationToken } from '../utils/setAuthorizationToken';
import { userAPI } from '../api/userAPI';
import { appLoading, setMessage, setIsAuthenticated } from './appSlice';

// async thunk request to User Request
export const getUserRequest = createAsyncThunk(
  'user/getUserRequest', // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.getCpUser();
      dispatch(setUserDetail(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Login
// eslint-disable-next-line
export const loginRequest = createAsyncThunk('user/loginRequest', async (userdata, { dispatch, rejectWithValue }) => {
  try {
    dispatch(appLoading(true));
    const getToken = await userAPI.loginRequest(userdata);
    localStorage.setItem('cpToken', JSON.stringify(getToken.accessToken));
    setAuthorizationToken(getToken.accessToken);
    dispatch(setIsAuthenticated(true));
    dispatch(getUserRequest());
    dispatch(appLoading(false));
  } catch (error) {
    if (error.response) {
      dispatch(appLoading(false));
      return rejectWithValue(error.response.data.message);
    }
  }
});

// async thunk request to User Log OUT
export const userLogoutRequest = createAsyncThunk(
  'user/userLogoutRequest', // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.logoutCall();
      dispatch(setMessage(data.message));
      localStorage.removeItem('cpToken');
      setAuthorizationToken(false);
      dispatch(setIsAuthenticated(false));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to User Log OUT
export const changePasswordRequest = createAsyncThunk(
  'user/changePasswordRequest', // eslint-disable-next-line
  async (changeData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.changePassword(changeData);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response?.data?.message);
      }
    }
  }
);

// async thunk request to Partner Account Balance
export const getBalanceRequest = createAsyncThunk(
  'user/getBalanceRequest', // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.getMyBalance();
      dispatch(setPartnerBalance(data.balance));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to User PORTFOLIO
export const portfolioRequest = createAsyncThunk(
  'user/portfolioRequest', // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.totalPortfolio();
      dispatch(setPortfolio(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Deposit History
export const depositHistoryRequest = createAsyncThunk(
  'user/depositHistoryRequest', // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.depositHistory();
      dispatch(setDepositHistory(data.depositHistory));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Withdraw History
export const makeWithdrawlRequest = createAsyncThunk(
  'user/makeWithdrawlRequest', // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.sendWithdrwalRequest(userdata);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Withdraw History
export const withdrawHistoryRequest = createAsyncThunk(
  'user/withdrawHistoryRequest', // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.withdrawHistory();
      dispatch(setWithdrawHistory(data.withdrawHistory));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get my transactions list
export const getPartnerTransactionsRequest = createAsyncThunk(
  'user/getPartnerTransactionsRequest', // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.myTransactions();
      dispatch(setPartnerTransactions(data.list));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get my clients list
export const getAllClientsRequest = createAsyncThunk(
  'user/getAllClientsRequest', // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.myClients();
      dispatch(setMyClients(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const initialState = {
  userDetails: {},
  portfolio: {},
  hasError: '',
  depositHistory: [],
  withdrawHistory: [],
  partnerBalance: 0,
  myTransactions: [],
  myClients: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      state.userDetails = action.payload;
    },
    setPortfolio: (state, action) => {
      state.portfolio = action.payload;
    },
    setDepositHistory: (state, action) => {
      state.depositHistory = action.payload;
    },
    setWithdrawHistory: (state, action) => {
      state.withdrawHistory = action.payload;
    },
    setPartnerBalance: (state, action) => {
      state.partnerBalance = action.payload;
    },
    setPartnerTransactions: (state, action) => {
      state.myTransactions = action.payload;
    },
    setMyClients: (state, action) => {
      state.myClients = action.payload;
    },
    clearHasError: (state) => {
      state.hasError = '';
    }
  },
  extraReducers: {
    [getUserRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [loginRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [userLogoutRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [changePasswordRequest.rejected]: (state, action) => {
      console.log(action, 'action');
      state.hasError = action.payload;
    },
    [portfolioRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [depositHistoryRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [withdrawHistoryRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getAllClientsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    }
  }
});
export const {
  setUserDetail,
  setPortfolio,
  setDepositHistory,
  setWithdrawHistory,
  setPartnerBalance,
  setPartnerTransactions,
  clearHasError,
  setMyClients
} = userSlice.actions;

export default userSlice.reducer;

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    pageOne: path(ROOTS_DASHBOARD, '/main'),
    pageTwo: path(ROOTS_DASHBOARD, '/client-deposits'),
    pageThree: path(ROOTS_DASHBOARD, '/client-withdrawl'),
    pageFour: path(ROOTS_DASHBOARD, '/my-transactions'),
    pageFive: path(ROOTS_DASHBOARD, '/my-clients'),
    changePasswordPage: path(ROOTS_DASHBOARD, '/change-password')
  }
};
